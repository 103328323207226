// About.js
import React from 'react';
import {Container} from 'react-bootstrap';
import Layout from "../../components/layout";
import {StaticImage} from "gatsby-plugin-image";

class DojoKun extends React.Component {
    render() {
        return (
            <Layout>
                <h1>What is our Dojokun</h1>
                <div className="libraryImages">
                    <StaticImage src="../../images/dojokun.jpg" alt="Sanchin"/>
                </div>
                <p>(Kanji reads from top right downwards on each verticle line, working across to the far left verticle
                    line.)</p>

                <h2>HITOTSU: JINKAKU KANSEI NI TSUTOMERU KOTO</h2>
                <p>Work to perfect your character</p>

                <h2>HITOTSU: REIGI O OMONJIRU KOTO</h2>
                <p>Always act with good manners</p>

                <h2>HITOTSU: KEKKI NO YU O IMASHIMERU KOTO</h2>
                <p>Refrain from violent and uncontrolled behaviour</p>

                <h2>HITOTSU: DORYOKU NO SEISHIN O YASHINAU KOTO</h2>
                <p>Cultivate a spirit of endeavour and perseverance</p>

                <h2>HITOTSU: MAKOTO NO MICHI O MAMORU KOTO</h2>
                <p>Have fidelity in seeking a true way</p>
            </Layout>);
    }
}

export default DojoKun;
